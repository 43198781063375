import TextTransition, { presets } from "react-text-transition";
import React, {useEffect, useState} from "react";
import styles from "./Home.module.css";

const words = [
    "Software Engineer",
    "Computer Scientist",
    "Freelancer",
    "Full-Stack Developer",
]

function Home() {
    const [index, setIndex] = useState(Math.floor(Math.random() * words.length));
    const [mobile, setMobile] = useState(window.matchMedia('(min-width: 767px)').matches);

    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 767px)');
        const handler = (e: any) => setMobile(e.matches);
        mediaQuery.addEventListener("change", handler);
        setMobile(mediaQuery.matches);

        return () => {
            mediaQuery.removeEventListener("change", handler);
        };
    }, []);

    useEffect(() => {
        const intervalId = setInterval(
            () => setIndex((index) => (index + 1) % words.length),
            5000,
        );
        return () => clearTimeout(intervalId);
    }, []);

    const handleWork = () => {
        document.getElementById("experience")?.scrollIntoView({ behavior: "smooth" });
    }

    return (
        <div
            className={styles.container}
        >
            <div>
                <div className={styles.portrait_border}>
                    <img
                        src={"/img/portrait.jpg"}
                        alt="Portrait"
                    />
                </div>
                <h1>Hi, I'm Bryan</h1>
                <h2>
                    I'm a&nbsp;
                    <TextTransition
                        className={styles.text_transition}
                        inline={true}
                        springConfig={presets.slow}
                        translateValue={"80%"}
                        direction={"down"}
                    >{words[index]}</TextTransition>
                </h2>
            </div>
            <div className={styles.buttons}>
                {mobile && (<>
                    <button className={styles.button_styled} onClick={handleWork}>
                        <span>Check out my previous work</span></button>
                    <span className={styles.between_text}>or</span></>)
                }
                <a
                    className={styles.button_styled}
                    href={"mailto:bryan@bbvandermark.com"}
                >
                    <span>Contact me</span>
                </a>
                <p className={styles.subtext}>bryan (at) bbvandermark (dot) com</p>
            </div>
        </div>
    )
}

export default Home;